<!-- 账单 -->
<template>
  <div id="electricityFee">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('funnyBill.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <div class="boxstyle">
        <van-field
          readonly
          clickable
          :value="month"
          placeholder="选择时间"
          class="picker"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="months"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
        <p>{{ $t('funnyBill.monthTotal') }}<span>&nbsp;&nbsp;{{ payOrderStatisticsVO.count == null? '0' : payOrderStatisticsVO.count }}&nbsp;&nbsp;</span>{{ $t('funnyBill.total') }}<span style="float:right;color:red">¥&nbsp;{{ payOrderStatisticsVO.sumAmount == null? '0' : payOrderStatisticsVO.sumAmount }}</span></p>
      </div>
      <img :src="imageL[0]" style="width: 87%;left: 6.5%;position: absolute;">
      <div class="boxstylemoney">
        <div>{{ $t('funnyBill.mostUse') }}:<span>{{ mostUsed === '' ?'当月无交易':mostUsed }}</span></div>
        <div>{{ $t('funnyBill.totalPay') }}:<span>{{ maxAmount === '' ?'当月无交易':maxAmount }}</span></div>
        <div>{{ $t('funnyBill.singlePay') }}:<span>{{ maxSingleAmount === '' ?'当月无交易':maxSingleAmount }}</span></div>
      </div>
      <img :src="imageL[0]" style="width: 87%;left: 6.5%;position: absolute;">
      <div class="boxstylemoney">
        <div id="myChart" :style="{width: '300px', height: '300px'}" />
      </div>
      <img :src="imageL[0]" style="width: 87%;left: 6.5%;position: absolute;">
      <div class="boxstylemoney">
        <div id="myChart2" :style="{width: '300px', height: '300px'}" />
      </div>
    </div>
    <!-- 回到顶部按钮 -->
    <!-- <v-top /> -->
  </div>
</template>

<script>
// import { pageListReiForm } from '@/api/reiform'
// import moment from 'moment/moment'
// import ReiTabbar from '../components/ReiTabbar.vue'
import { getTradeStatisticsByMonth, getTradeMostUsed, getTradeMaxAmount, getTradeMaxSingleAmount } from '@/api/orderTrade.js'
import { getPayTypeStatistics, getTradeStaticsGroupByMonth } from '@/api/orderTrade.js'
export default {
  name: 'Project',
  components: {
    // ReiTabbar
  },
  data() {
    return {
      orderList: [], // 后台返回查询数据
      orderData: [],
      monthGroupList: [],
      monthList: [],
      valueList: [],
      value: '2020年五月',
      showPicker: false,
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto',
        // background: "#EFF6FF"
        background: 'transparent'
      },
      imageL: [
        require('../../assets/newImages/line.png')
      ],
      months: [],
      month: '',
      payOrderStatisticsVO: '',
      mostUsed: '',
      maxAmount: '',
      maxSingleAmount: ''
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    // this.getUserInfo()
    this.init()
    this.getTradeStaticsByMonth()
    this.getTradeMostUsed()
    this.getTradeMaxAmount()
    this.getTradeMaxSingleAmount()
    this.getOrderStatistics()
    this.getTradeStaticsGroupByMonth()

    // this.drawLine()
    this.drawLine2()
  },
  methods: {
    drawLine() {
    // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myChart'), 'macarons')
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.$t('funnyBill.payItem'),
          left: 'left',
          textStyle: {
            color: '#333',
            fontSize: 18 // 务必记住设置文字大小只能是数字
            // fontWeight: 'lighter'
          }, // 主标题样式
          subtext: this.$t('funnyBill.unit'),
          subtextStyle: {
            color: '#999999',
            fontSize: 12,
            fontWeight: 'normal'
          } // 副标题样式
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'horizontal',
          x: 'right',
          y: 'bottom',
          data: ['1', '2', '3']
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.orderData
          }
        ]
      })
    },
    drawLine2() {
    // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myChart2'), 'macarons')
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.$t('funnyBill.mounth'),
          left: 'left',
          textStyle: {
            color: '#333',
            fontSize: 18 // 务必记住设置文字大小只能是数字
            // fontWeight: 'lighter'
          }, // 主标题样式
          subtext: this.$t('funnyBill.unit'),
          subtextStyle: {
            color: '#999999',
            fontSize: 12,
            fontWeight: 'normal'
          } // 副标题样式
        },
        tooltip: {
          trigger: 'axis'
        },
        // x轴
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {},
        // 数据
        series: [{
          name: '销量',
          type: 'bar',
          itemStyle: {
            normal: {
              color: function(params) {
                var colorList = ['#66B7F3']
                return colorList[params.dataIndex]
              }
            }
          },
          data: this.valueList
        }
        ]
      })
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/home' })
    },
    // 获取数据
    getList() {
      setTimeout(() => {
      }, 1000)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/person'
      })
    },
    init() {
      var myDate = new Date()
      var year = myDate.getFullYear() // 获取当前年
      var myMonth = myDate.getMonth() + 1
      if (myMonth < 10) {
        this.month = year + '-0' + myMonth
      } else {
        this.month = year + '-' + myMonth
      }
      for (var i = 0; i < 4; i++) {
        var month = myDate.getMonth() + 1 - i // 获取当前月
        if (myMonth < 10) {
          this.months.push(year + '-0' + month)
        } else {
          this.months.push(year + '-' + month)
        }
      }
    },
    goToOrder() {
      this.$router.push({ name: 'orderlist' })
    },
    getTradeStatics() {
      this.payOrderStatisticsVO = ''
      this.mostUsed = ''
      this.maxAmount = ''
      this.maxSingleAmount = ''
      this.getTradeMostUsed()
      this.getTradeMaxAmount()
      this.getTradeMaxSingleAmount()
    },
    getTradeStaticsByMonth() {
      getTradeStatisticsByMonth(window.localStorage.getItem('schoolcode'), this.month).then(response => {
        this.payOrderStatisticsVO = response.data
      })
    },
    getTradeMostUsed() {
      getTradeMostUsed(window.localStorage.getItem('schoolcode'), this.month).then(response => {
        if (response.data !== null) {
          this.mostUsed = response.data.mostUsed
        }
      })
    },
    getTradeMaxAmount() {
      getTradeMaxAmount(window.localStorage.getItem('schoolcode'), this.month).then(response => {
        if (response.data !== null) {
          this.maxAmount = response.data.maxAmount
        }
      })
    },
    getTradeMaxSingleAmount() {
      getTradeMaxSingleAmount(window.localStorage.getItem('schoolcode'), this.month).then(response => {
        if (response.data !== null) {
          this.maxSingleAmount = response.data.maxSingleAmount
        }
      })
    },
    getOrderStatistics() {
      this.orderList = []
      this.orderData = []
      getPayTypeStatistics(window.localStorage.getItem('schoolcode'), this.month).then(response => {
        this.orderList = response.data
        this.orderList.forEach(item => {
          const obj = {}
          obj.name = item.tradeType
          obj.value = item.sumAmount
          this.orderData.push(obj)
          console.log(this.orderData)
        })
        this.drawLine()
      })
    },
    getTradeStaticsGroupByMonth() {
      getTradeStaticsGroupByMonth(window.localStorage.getItem('schoolcode')).then(response => {
        this.monthGroupList = response.data
        for (var i = 0; i < 11; i++) {
          this.valueList.push('0')
        }
        for (var j = 0; j < this.monthGroupList.length; j++) {
          this.valueList[this.monthGroupList[j].tradeMonth - 1] = this.monthGroupList[j].sumAmount
        }
        this.drawLine2()
      })
    },
    onConfirm(value) {
      this.month = value
      this.showPicker = false
      this.getOrderStatistics()
      this.getTradeStaticsGroupByMonth()
      this.getTradeStatics()
      this.getTradeStaticsByMonth()
    }
  }
}
</script>
<style scoped lang="scss">
#electricityFee {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  padding:15px 0 0 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    margin: 20px;
    span{
      color: #1989fa;
    }
  }
}
.boxstylemoney{
  margin: 10px 15px 0px 15px;
  padding:20px 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 10px #dde9f3;
  div{
    font-size:14px;
    margin: 20px 20px 10px 20px;
    color:#666;
    span{
      float:right;
      color:#1989fa;
    }
  }
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.lastbattry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: #1989fa;
    float: right;
  }
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  height: 80px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>

